import React from "react";
import bannerTeste from "../../assets/images/banner-teste.png";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { showTopbarInsideAction } from "../../store/actions";
import { TopBarInsideTypes } from "../../constants/layout";

const Ui = () => {
  document.title = "UI Labolatory";

  const dispatch = useDispatch();

  const topBarInsideMenus = [
    {
      ID_Menu_Pai: 0,
      ID_Menu: 1,
      Descricao: "UI Components",
      active: true,
      Icone: "mdi mdi-briefcase-variant-outline",
      Codigo_Transacao: "/",
      Dropdown: 'S',
      childrens: [
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Alerts",
          Codigo_Transacao: "/ui-alerts",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Badge",
          Codigo_Transacao: "/ui-badge",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Breadcrumb",
          Codigo_Transacao: "/ui-breadcrumb",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Buttons",
          Codigo_Transacao: "/ui-buttons",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Cards",
          Codigo_Transacao: "/ui-cards",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Carousel",
          Codigo_Transacao: "/ui-carousel",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Dropdowns",
          Codigo_Transacao: "/ui-dropdowns",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Grid",
          Codigo_Transacao: "/ui-grid",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Images",
          Codigo_Transacao: "/ui-images",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Lightbox",
          Codigo_Transacao: "/ui-lightbox",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Modals",
          Codigo_Transacao: "/ui-modals",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Offcanvas",
          Codigo_Transacao: "/ui-offcanvas",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Rangeslider",
          Codigo_Transacao: "/ui-rangeslider",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Sessiontimeout",
          Codigo_Transacao: "/ui-sessiontimeout",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Pagination",
          Codigo_Transacao: "/ui-pagination",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Progressbars",
          Codigo_Transacao: "/ui-progressbars",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Placeholders",
          Codigo_Transacao: "/ui-placeholders",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Tabs Accordions",
          Codigo_Transacao: "/ui-tabs-accordions",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Typography",
          Codigo_Transacao: "/ui-typography",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Toasts",
          Codigo_Transacao: "/ui-toasts",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Video",
          Codigo_Transacao: "/ui-video",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Popovers",
          Codigo_Transacao: "/ui-popovers",
        },
        {
          ID_Menu_Pai: 1,
          Descricao: "UI Rating",
          Codigo_Transacao: "/ui-rating",
        },
      ],
    },
    {
      ID_Menu_Pai: 0,
      ID_Menu: 2,
      Descricao: "Forms",
      active: true,
      Icone: "ri-eraser-fill",
      Codigo_Transacao: "/cot001",
      Dropdown: 'S',
      childrens: [
        {
          ID_Menu_Pai: 2,
          Descricao: "Form elements",
          Codigo_Transacao: "/form-elements",
        },
        {
          ID_Menu_Pai: 2,
          Descricao: "Form validation",
          Codigo_Transacao: "/form-validation",
        },
        {
          ID_Menu_Pai: 2,
          Descricao: "Form Advanced",
          Codigo_Transacao: "/form-advanced",
        },
        {
          ID_Menu_Pai: 2,
          Descricao: "Form Editor",
          Codigo_Transacao: "/form-editor",
        },
        {
          ID_Menu_Pai: 2,
          Descricao: "Form Uploads",
          Codigo_Transacao: "/form-uploads",
        },
        {
          ID_Menu_Pai: 2,
          Descricao: "Form Editors",
          Codigo_Transacao: "/form-editors",
        },
        {
          ID_Menu_Pai: 2,
          Descricao: "Form Wizard",
          Codigo_Transacao: "/form-wizard",
        },
        {
          ID_Menu_Pai: 2,
          Descricao: "Form Mask",
          Codigo_Transacao: "/form-mask",
        },
      ],
    },
    {
      ID_Menu_Pai: 0,
      Descricao: "Tables",
      active: true,
      Icone: "ri-table-2",
      Codigo_Transacao: '/',
      Dropdown: 'S',
      childrens: [
        {
          Descricao: "Tables Basic",
          Codigo_Transacao: "/tables-basic",
        },
        {
          Descricao: "Tables Listjs",
          Codigo_Transacao: "/tables-listjs",
        },
        {
          Descricao: "Tables Datatables",
          Codigo_Transacao: "/table-datatables",
        },
      ]
    },
    {
      ID_Menu_Pai: 0,
      Descricao: "Charts",
      active: true,
      Icone: "ri-bar-chart-line",
      Codigo_Transacao: '/',
      Dropdown: 'S',
      childrens: [
        {
          Descricao: "Chart Apexcharts",
          Codigo_Transacao: "/chart-apexcharts",
        },
        {
          Descricao: "Chart Chartjscharts",
          Codigo_Transacao: "/chart-chartjscharts",
        },
        {
          Descricao: "Chart Floatcharts",
          Codigo_Transacao: "/chart-floatcharts",
        },
        {
          Descricao: "Chart Jknobcharts",
          Codigo_Transacao: "/chart-jknobcharts",
        },
        {
          Descricao: "Chart Sparklinecharts",
          Codigo_Transacao: "/chart-sparklinecharts",
        },
      ]
    },
    {
      ID_Menu_Pai: 0,
      Descricao: "Icons",
      active: true,
      Icone: "ri-brush-line",
      Codigo_Transacao: '/',
      Dropdown: 'S',
      childrens: [
        {
          Descricao: 'Icon Boxicon',
          Codigo_Transacao: '/icon-boxicon'
        },
        {
          Descricao: 'Icons Materialdesign',
          Codigo_Transacao: '/icons-materialdesign'
        },
        {
          Descricao: 'Icon Dripicons',
          Codigo_Transacao: '/icon-dripicons'
        },
        {
          Descricao: 'Icons Fontawesome',
          Codigo_Transacao: '/icons-fontawesome'
        },
      ]
    },
    {
      ID_Menu_Pai: 0,
      Descricao: "Maps",
      active: true,
      Icone: "ri-map-pin-line",
      Codigo_Transacao: '/',
      Dropdown: 'S',
      childrens: [
        {
          Descricao: 'Maps Google',
          Codigo_Transacao: '/maps-google'
        },
        {
          Descricao: 'Maps Vector',
          Codigo_Transacao: '/maps-vector'
        },
      ]
    }
  ];

  dispatch(showTopbarInsideAction({isActive: TopBarInsideTypes.isActive.ACTIVE, payload: topBarInsideMenus}));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <img
            style={{ objectFit: "contain", width: "100%" }}
            src={bannerTeste}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Ui;
