// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TimeLine CSS */
.hori-timeline-desc .slick-prev::before,
.hori-timeline-desc .slick-next::before{
    display: none;
}

 .slick-prev {
    left: 150px;
    top: 40px;
    z-index: 1;
}

.slick-next {
    right: -150px;
    top: 40px;
    z-index: 1;
}

`, "",{"version":3,"sources":["webpack://./src/Pages/Utility/TimeLine.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;;IAEI,aAAa;AACjB;;CAEC;IACG,WAAW;IACX,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,SAAS;IACT,UAAU;AACd","sourcesContent":["/* TimeLine CSS */\n.hori-timeline-desc .slick-prev::before,\n.hori-timeline-desc .slick-next::before{\n    display: none;\n}\n\n .slick-prev {\n    left: 150px;\n    top: 40px;\n    z-index: 1;\n}\n\n.slick-next {\n    right: -150px;\n    top: 40px;\n    z-index: 1;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
