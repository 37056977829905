// utils.js

export const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

export const formatDateToBrazilian_filtro = (dateString) => {
  // Verifica se a data está vazia ou nula
  if (!dateString) {
    console.log("Data recebida é nula ou indefinida");
    return 'Data não definida';
  }

  const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'America/Sao_Paulo' };

  // Divide a data em componentes e cria uma nova data considerando o fuso horário
  const [year, month, day] = dateString.split('-');
  const date = new Date(Date.UTC(year, month - 1, day, 3)); // Adiciona 3 horas para compensar o fuso de São Paulo

  // Formata a data para o formato brasileiro
  return date.toLocaleDateString('pt-BR', options);
};

export const formatDateToBrazilian = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString('pt-BR', options);
};

export const handleToggle = async (id, data, setData, atualizaStatus) => {
  const updatedData = data.map(item => {
    if (item.ID_Tarefa === id) {
      const newStatus = item.Ativo === 'S' ? 'N' : 'S';
      atualizaStatus(newStatus, id);  // Chamada da função para atualizar o status na API
      return { ...item, Ativo: newStatus };
    }
    return item;
  });
  setData(updatedData);
};

export const getBrazilianTime = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const hours = date.getUTCHours(); // Pega a hora no fuso horário UTC
  const brazilianHours = (hours - 3 + 24) % 24; // Converte para o fuso horário de Brasília (UTC-3)

  // Formata para exibir em formato 24h
  const formattedHours = brazilianHours.toString().padStart(2, '0');
  const formattedMinutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};