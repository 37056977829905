import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from 'axios'; // Importando o axios para fazer chamadas HTTP

//import Filtros from '../../Layout/VerticalLayout/Filtros'; // Ajuste o caminho conforme necessário

import Filtros from "../../components/Common/Filtros";
import config from "../../config";
import { useCookies } from "react-cookie";

const Transporte = () => {
  document.title = "Basic Tables | Upzet - React Admin & Dashboard Template";

  const [isFiltrosOpen, setIsFiltrosOpen] = useState(false);
  const [cookie, setCookie] = useCookies(['_csrf_token'])

  const toggleFiltros = () => {
    setIsFiltrosOpen(!isFiltrosOpen);
  };

  // Estado para armazenar os dados da API
  const [data, setData] = useState([]);

  const [filterText, setFilterText] = useState('');
  const filteredData = data //.filter(item => item.Categoria && item.Categoria.toLowerCase().includes(filterText.toLowerCase()));

  const handlerFilter = (params) => {
    console.log("Parâmetros recebidos pelo handlerFilter:"); // Adicione este log
    
    console.log("Parâmetros recebidos pelo handlerFilter:", params); // Adicione este log
    setIsFiltrosOpen(!isFiltrosOpen);
    fetchData(params)
  }
  
  // const exportPDF = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({
  //     head: [['ID Arquivo', 'Numero Documento', 'Tipo Documento', 'Empresa Destino', 'Empresa Origem']],
  //     body: filteredData.map(item => [item.ID_Arquivo, item.Numero_Documento, item.Tipo_Documento, item.Empresa_Destino, item.Empresa_Origem]),
  //   });
  //   doc.save('relatorio.pdf');
  // };

  // Colunas para o DataTable
  const columns = [
    {
      name: 'Nome empresa',
      selector: row => row.Nome_Empresa,
      sortable: true,
    },
    {
      name: 'Nome fantasia',
      selector: row => row.Nome_Fantasia,
      sortable: true,
    }
  ];

  const fetchData = async (params) => {
    try {
      const nomeProcedimento = "FRONT_Obter_Lista_Empresas";

      let response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
        requestData: params,
        proc: nomeProcedimento
      },{
        headers: {
          "X-CSRF-Token": cookie?._csrf_token?.csrfToken
        }
      });
     
      setData(response.data);
      console.log("Dados recebidos da API (array):", response.data);


    } catch (error) {
      console.error("Erro ao buscar dados: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Tables" breadcrumbItem="Basic Transporte" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Basic Transporte</h4>
                  <p className="card-title-desc">
                  </p>

                  {/* <input
                    type="text"
                    placeholder="Pesquisar por Categoria"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                  /> */}
                  {/* <button onClick={exportPDF}>Exportar para PDF</button>     */}
                  
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    style={{ float: 'right', position: 'relative', top: '-37px' }}
                    onClick={toggleFiltros}
                  >
                    <i className="mdi mdi-filter"></i>
                    Filtros
                  </button>

                  <Filtros isOpen={isFiltrosOpen} onClose={toggleFiltros} callbackFilter={(params) => handlerFilter(params)} />

                  {data.length ? 
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      pagination={true}
                      title={"PROC - FRONT_Obter_Lista_Empresas"}
                      noDataComponent={"Nenhum registro encontrado"}
                      // Adicione outras propriedades conforme necessário
                    />
                  : 
                  <Spinner color="primary" size="md">
                      Carregando...
                  </Spinner>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transporte;
