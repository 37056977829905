import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

const PreFiltrosTeste = ({ onFilterSelect, activeFilter }) => {
  const [activeTab, setActiveTab] = useState(activeFilter || 'NF');

  useEffect(() => {
    setActiveTab(activeFilter);
  }, [activeFilter]);

  const handleSelectFilter = (filter) => {
    setActiveTab(filter);
    onFilterSelect(filter);
  };

  return (
    <div className="container-fluid">
      <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
        <div className="collapse navbar-collapse" id="topnav-menu-content">
          <Nav className="navbar-nav mr-auto">
            {['NF', 'PED', 'TOP 100', 'TOP 1000', 'TOP 5000', 'TOP 10000', 'TOP 50000', 'TOP 100000', ].map((filter, index) => (
              <NavItem key={index}>
                <NavLink
                  active={activeTab === filter}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelectFilter(filter);
                  }}
                >
                  {filter}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </nav>
    </div>
  );
};

export default PreFiltrosTeste;
