module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  firebaseConfig: {
    vapidKey: "BIVmvCDsDCgTEYFDwdmYvqiloCHi6CYtldi5BpAGRLXRiJsgsAoja7GyzWb1vNKK85-ODIM4BWVq3tLn0_0vYsY",
    apiKey: "AIzaSyBDXvXD_gLAI1UUer8n2A6yHrx0u9g1oUk",
    authDomain: "web-bizin.firebaseapp.com",
    projectId: "web-bizin",
    storageBucket: "web-bizin.appspot.com",
    messagingSenderId: "706114036210",
    appId: "1:706114036210:web:b8985c2cc04d866a82cb6b",
    measurementId: "G-DQJ9QCK7Y5"
  },
  SECRET_KEY: 'l5U2_dEqHnErvBWU_GEhUwWEj1WMW6r48mxku7Bzu94',
  API_PORT: 3040,
  
  //URL_HOST: 'https://dev.businessintegrator.com.br',//'http://150.230.71.65',
  URL_HOST: 'http://150.230.71.65:8090',
  
  //URL_SERVER: 'https://api-dev.businessintegrator.com.br',
  URL_SERVER: 'http://150.230.71.65:3040',

  MSMAN_USERNAME: 'APP_COMUNICACAO',
  MSMAN_PASSWORD: 'Emakers@123',
  
  //URL_MSMAN: 'https://msman-qas-oke.businessintegrator.com.br'
  URL_MSMAN: 'http://146.235.48.102'
  
 // URL_MSMAN: 'https://msman-prd-oke.businessintegrator.com.br'
 //URL_MSMAN: 'http://146.235.63.212'

}
