import React, { useState, useEffect, useRef } from "react";
import DataTable from 'react-data-table-component';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { formatDate, getBrazilianTime, formatDateToBrazilian, formatDateToBrazilian_filtro, handleToggle } from '../../components/Utils/formatDate';

import './assets/style.css'; // Ajuste o caminho para o local do seu arquivo CSS

import { 
  Card, CardBody, Col, Container, Row, 
  Spinner, 
  Dropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Toast, ToastHeader} from 'reactstrap';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from 'axios';
import FiltrosTeste from "../../components/Common/FiltrosTeste"; // Ajustado para usar o componente correto
import PreFiltrosTeste from "../../components/Common/PreFiltrosTeste";
import config from "../../config";
import { useCookies } from "react-cookie";

const Taskman = () => {
  document.title = "Basic Tables | Upzet - React Admin & Dashboard Template";

  const [isFiltrosOpen, setIsFiltrosOpen] = useState(false);
  const [cookie, setCookie] = useCookies(['_csrf_token']);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);  // Novo estado para controle do carregamento
  const [toastDataRange, setToastDataRange] = useState(true);  // Estado inicial false, toast não visível

  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [activeFilter, setActiveFilter] = useState('NF');  // Novo estado para o filtro ativo

  const [loadingTimes, setLoadingTimes] = useState({
    start: null,
    fetchStart: null,
    fetchEnd: null,
    renderStart: null,
    renderEnd: null
  });

  const hasFetched = useRef(false);

  const toggleFiltros = () => {
    setIsFiltrosOpen(!isFiltrosOpen);
  };

  const toggleDropdown = (id) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const toggleToastDataRange = () => setToastDataRange(!toastDataRange);

  const handleFilterSelect = (filterName) => {
    console.log('Filtro selecionado:', filterName);

    setActiveFilter(filterName);  // Atualizar o filtro ativo

    const paramsToFetch = {
      1: 2405,
      2: "S",
      3: filterName,
      4: formatDateToBrazilian(startDate),
      5: formatDateToBrazilian(endDate)
    };

    console.log('Parâmetros ajustados para busca:', paramsToFetch);

    fetchData(paramsToFetch);
    setIsFiltrosOpen(false);
  };

  const handlerFilter = (params) => {
    const adjustedParams = {
      1: 2405,
      2: "S",
      3: params.PreFiltro,
      4: formatDateToBrazilian(params.Data_Inicio),
      5: formatDateToBrazilian(params.Data_Termino)
    };

    setStartDate(params.Data_Inicio);
    setEndDate(params.Data_Termino);

    console.log('adjustedParams', adjustedParams);
    fetchData(adjustedParams);
  }

  const refreshTable = async () => {   
    const params = {
      1: 2405,
      2: "S",
      3: activeFilter,
      4: formatDateToBrazilian(startDate),
      5: formatDateToBrazilian(endDate)
    };

    fetchData(params);
  };

  const fetchData = async (params) => {
    console.log('fetchData chamado com params:', params);
    try {
      setLoading(true);  
      const fetchStart = performance.now();
      setLoadingTimes((prevTimes) => ({ ...prevTimes, fetchStart }));

      let nomeProcedimento;

      if (params[3] === 'NF') {
        nomeProcedimento = "FRONT_NotaFiscal_Obter_Lista_DecPerm";
      } else if (params[3] === 'PED') {
        nomeProcedimento = "FRONT_Pedido_Obter_Lista_DecPerm";
      } else if (params[3] === 'TOP 100') {
        nomeProcedimento = "FRONT_Teste_Performance";
        params = {1: 100};      
      } else if (params[3] === 'TOP 1000') {
        nomeProcedimento = "FRONT_Teste_Performance";
        params = {1: 1000};      
      } else if (params[3] === 'TOP 5000') {
        nomeProcedimento = "FRONT_Teste_Performance";
        params = {1: 5000};      
      } else if (params[3] === 'TOP 10000') {
        nomeProcedimento = "FRONT_Teste_Performance";
        params = {1: 10000};      
      } else if (params[3] === 'TOP 50000') {
        nomeProcedimento = "FRONT_Teste_Performance";
        params = {1: 50000};      
      } else if (params[3] === 'TOP 100000') {
        nomeProcedimento = "FRONT_Teste_Performance";
        params = {1: 100000};      
      }

      let response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
        requestData: params,
        proc: nomeProcedimento
      },{
        headers: {
          "X-CSRF-Token": cookie?._csrf_token?.csrfToken
        }
      });

      const fetchEnd = performance.now();
      setLoadingTimes((prevTimes) => ({ ...prevTimes, fetchEnd }));

      setData(response.data);
      console.log("Dados recebidos da API (array):", response.data);
    } catch (error) {
      console.error("Erro ao buscar dados: ", error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;

      const start = performance.now();
      setLoadingTimes((prevTimes) => ({ ...prevTimes, start }));

      const defaultParams = {
        1: 2405,
        2: "S",
        3: activeFilter,  // Usar o filtro ativo
        4: formatDateToBrazilian(startDate),
        5: formatDateToBrazilian(endDate)
      };

      console.log('defaultParams', defaultParams);
      fetchData(defaultParams);
    }
  }, [startDate, endDate, activeFilter]);

  useEffect(() => {
    if (!loading && loadingTimes.fetchEnd) {
      const renderStart = performance.now();
      setLoadingTimes((prevTimes) => ({ ...prevTimes, renderStart }));

      // Registrar renderEnd após renderização
      requestAnimationFrame(() => {
        const renderEnd = performance.now();
        setLoadingTimes((prevTimes) => ({ ...prevTimes, renderEnd }));
      });
    }
  }, [loading, loadingTimes.fetchEnd]);

  // Calcular as durações apenas se todos os tempos necessários estiverem disponíveis
  const fetchDuration = loadingTimes.fetchEnd && loadingTimes.fetchStart ? (loadingTimes.fetchEnd - loadingTimes.fetchStart) / 1000 : 0;
  const renderDuration = loadingTimes.renderEnd && loadingTimes.renderStart ? (loadingTimes.renderEnd - loadingTimes.renderStart) / 1000 : 0;
  // Ajuste no cálculo do tempo total
  const totalDuration = fetchDuration + renderDuration;

  const columns = [
    {
      name: 'ID Arquivo',
      selector: row => row.ID_Arquivo,
      sortable: true,
    }
    // Adicione mais colunas conforme necessário
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Tables" breadcrumbItem="Taskman" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <PreFiltrosTeste onFilterSelect={handleFilterSelect} activeFilter={activeFilter} />
                  <p className="card-title-desc"></p>
                  
                  <div className="loading-times">
                    <p><strong>Tempo de busca de dados:</strong> {fetchDuration.toFixed(2)} s</p>
                    <p><strong>Tempo de renderização:</strong> {renderDuration.toFixed(2)} s</p>
                    <p><strong>Tempo total:</strong> {totalDuration.toFixed(2)} s</p>
                  </div>

                  <div
                    className="toast fade show align-items-center text-white border-0"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                  >
                    <div className="d-flex">
                      <Toast isOpen={toastDataRange}>
                        <ToastHeader toggle={toggleToastDataRange}>
                          Período: {formatDateToBrazilian_filtro(startDate)} - {formatDateToBrazilian_filtro(endDate)}
                        </ToastHeader>
                      </Toast>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    style={{ float: 'right', position: 'relative', top: '-37px' }}
                    onClick={toggleFiltros}
                  >
                    <i className="mdi mdi-filter"></i>
                    Filtros
                  </button>

                  <FiltrosTeste 
                    isOpen={isFiltrosOpen} 
                    onClose={toggleFiltros} 
                    callbackFilter={(params) => handlerFilter(params)} 
                    initialFilter={activeFilter} // Passar o filtro inicial
                  />

                  {loading ? (
                    <Spinner color="primary" size="md">
                      Carregando...
                    </Spinner>
                  ) : data.length ? (
                    <div className="data-table-container">
                      <DataTable
                        columns={columns}
                        data={data}
                        pagination={true}
                        title={""}
                        noDataComponent={"Nenhum registro encontrado"}
                      />
                    </div>
                  ) : (
                    <div className="text-center">
                      <p>Sem dados para essa consulta...</p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Taskman;
