import React, { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'; // useNavigate para redirecionar
import { logoutUser } from '../../store/actions'; // Ação para limpar o usuário no Redux
import config from "../../config"; // Certifique-se de que o caminho esteja correto
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';

const NewPassword = () => {
    document.title = "Change Password | Upzet - React Admin & Dashboard Template";

    const dispatch = useDispatch();
    const navigate = useNavigate(); // Para redirecionar o usuário
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning'); // 'success' or 'warning'
    const [messageVisible, setMessageVisible] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [cookie] = useCookies(['_csrf_token']);

    // Recuperar ID do usuário do localStorage
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const userId = authUser?.ID_Usuario;

    // Função para enviar a solicitação de atualização de senha
    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        
        // Verificar se as novas senhas coincidem
        if (newPassword !== confirmNewPassword) {
            setMessage('As senhas não coincidem.');
            setMessageType('warning');
            setMessageVisible(true);
            return;
        }

        try {
            // Realizar a chamada à API e tratar a resposta
            const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
                proc: 'FRONT_Usuario_Atualizar_Senha',
                requestData: { 
                    "ID_Usuario": userId, 
                    "Atual_Senha": currentPassword, 
                    "Nova_Senha": newPassword 
                }
            }, {
                headers: {
                    "X-CSRF-Token": cookie?._csrf_token?.csrfToken
                }
            });
            
            // Tratar a resposta da API
            const result = response.data[0];
                
            if (result && result.statusCode === 200) {
                // Mensagem de sucesso
                setMessage(result.message);
                setMessageType('success');
                setIsSuccess(true);

                // Limpar dados do usuário
                localStorage.removeItem("authUser"); // Remove dados do localStorage
                dispatch(logoutUser()); // Redefine o usuário no Redux
                setTimeout(() => {
                    navigate('/login'); // Redirecionar para o login
                }, 2000); // Aguarde 2 segundos antes de redirecionar
            } else {
                // Tratar como erro se o statusCode não for 200
                setMessage(result?.message || 'Erro ao atualizar a senha.');
                setMessageType('warning');
            }
        } catch (error) {
            // Tratar erros da chamada à API
            setMessage('Erro ao atualizar a senha. Tente novamente mais tarde.');
            setMessageType('warning');
        }

        // Tornar a mensagem visível
        setMessageVisible(true);
    };

    return (
        <React.Fragment>
            <div className="bg-pattern" style={{height:"100vh"}}>
                <div className="bg-overlay"></div>
                <div className="account-pages pt-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6} md={8} xl={4}>
                                <Card className='mt-5'>
                                    <CardBody className="p-4">
                                        <div className="">
                                            <div className="text-center">
                                                 <Link to="/" className="">
                                                    <img src={logodark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                                                    <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                                                 </Link>
                                            </div>
                                            <h4 className="font-size-18 text-muted mt-2 text-center">Atualizar Senha</h4>
                                            <p className="mb-5 text-center"></p>
                                            {messageVisible && (
                                                <div className={`alert alert-${messageType} alert-dismissible`}>
                                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMessageVisible(false)}></button>
                                                    {message}
                                                </div>
                                            )}
                                            
                                            {!isSuccess ? (
                                                <form className="form-horizontal" onSubmit={handlePasswordUpdate}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="mt-4">
                                                                <label className="form-label" htmlFor="currentPassword">Senha Atual</label>
                                                                <input 
                                                                    type="password" 
                                                                    className="form-control" 
                                                                    id="currentPassword" 
                                                                    placeholder="Digite a senha atual" 
                                                                    value={currentPassword}
                                                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mt-4">
                                                                <label className="form-label" htmlFor="newPassword">Nova Senha</label>
                                                                <input 
                                                                    type="password" 
                                                                    className="form-control" 
                                                                    id="newPassword" 
                                                                    placeholder="Digite a nova senha" 
                                                                    value={newPassword}
                                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mt-4">
                                                                <label className="form-label" htmlFor="confirmNewPassword">Redigite a Nova Senha</label>
                                                                <input 
                                                                    type="password" 
                                                                    className="form-control" 
                                                                    id="confirmNewPassword" 
                                                                    placeholder="Redigite a nova senha" 
                                                                    value={confirmNewPassword}
                                                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="d-grid mt-4">
                                                                <button 
                                                                    className="btn btn-primary waves-effect waves-light" 
                                                                    type="submit"
                                                                >
                                                                    Atualizar Senha
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </form>
                                            ) : (
                                                // Mostrar o link para voltar ao login quando for sucesso
                                                <div className="text-center mt-4">
                                                    <Link to="/login" className="btn btn-primary">Clique aqui para voltar ao Login</Link>
                                                </div>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p className="text-white-50">Lembrou sua senha? <Link to="/login" className="fw-medium text-primary"> Voltar para o Login </Link> </p>
                                    <p className="text-white-50">© {new Date().getFullYear()} Upzet. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NewPassword;
