import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { Input, InputGroup, InputGroupText, FormGroup, Label , Button } from 'reactstrap';

import axios from 'axios';
import config from "../../config"; // Certifique-se de que o caminho esteja correto
import { useCookies } from "react-cookie";

import AsyncSelect from 'react-select/async';

export const ComboboxIntercambio = ({ ID_Empresa, ID_Empresa_Parceira, ID_Documento_Grupo, onChange }) => {
  const [intercambios, setIntercambios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cookie] = useCookies(['_csrf_token']);

  useEffect(() => {
    const fetchIntercambios = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
          proc: 'FRONT_Permissao_Obter_Intercambio',
          requestData: { "ID_Empresa": ID_Empresa, "ID_Empresa_Parceira": ID_Empresa_Parceira, "ID_Documento_Grupo": ID_Documento_Grupo }
          //requestData: { "ID_Empresa": 83, "ID_Empresa_Parceira": 995, "ID_Documento_Grupo": 2 }
        }, {
          headers: {
            "X-CSRF-Token": cookie?._csrf_token?.csrfToken
          }
        });

        setIntercambios(response.data);
      } catch (err) {
        console.log("Erro ao carregar intercâmbios:", err);
        setError('Erro ao carregar intercâmbios');
      } finally {
        setLoading(false);
      }
    };

    if (ID_Empresa && ID_Empresa_Parceira && ID_Documento_Grupo) {
      fetchIntercambios();
    }
  }, [ID_Empresa, ID_Empresa_Parceira, ID_Documento_Grupo]);

  if (loading) {
    return <p>Carregando intercâmbios...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <FormGroup>
      <Label for="Documento">Intercambio</Label>
    <Input type="select" onChange={onChange}>     
      {intercambios.map((intercambio) => (
        <option key={intercambio.ID_Intercambio} value={intercambio.ID_Intercambio}>
          {intercambio.Intercambio}
        </option>
      ))}
    </Input>
    </FormGroup>
  );
};


export const ComboboxEmpresa = ({ ID_Usuario, onChange }) => {
  const [cookie] = useCookies(['_csrf_token']);
  const [allOptions, setAllOptions] = useState([]);
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [displayCount, setDisplayCount] = useState(10); // Inicialmente exibe 10 itens

  useEffect(() => {
    // Carrega todos os itens de uma vez quando o componente é montado
    const fetchAllOptions = async () => {
      try {
        const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
          proc: 'FRONT_Permissao_Obter_Empresa',
          requestData: { "ID_Usuario": ID_Usuario, query: '' } // Remove a paginação e carrega todos os dados
        }, {
          headers: {
            "X-CSRF-Token": cookie?._csrf_token?.csrfToken
          }
        });

        const empresasOptions = response.data.map((empresa) => ({
          value: empresa.ID_Empresa,
          label: empresa.Empresa,
        }));

        setAllOptions(empresasOptions); // Salva todos os resultados
        setDisplayedOptions(empresasOptions.slice(0, displayCount)); // Inicialmente mostra apenas os primeiros 10 itens
      } catch (error) {
        console.error("Erro ao carregar empresas:", error);
      }
    };

    fetchAllOptions();
  }, [ID_Usuario]);

  const loadOptions = (inputValue, callback) => {
    if (inputValue) {
      const filteredOptions = allOptions.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    } else {
      callback(displayedOptions);
    }
  };

  const handleScroll = () => {
    const nextCount = displayCount + 10;
    const newOptions = allOptions.slice(0, nextCount);
    setDisplayedOptions(newOptions);
    setDisplayCount(nextCount);
  };

  return (
    <div className="mb-3 templating-select select2-container">
      <AsyncSelect
        cacheOptions
        defaultOptions={displayedOptions} // Mostra apenas os itens carregados atualmente
        loadOptions={loadOptions}
        onChange={onChange}
        onMenuScrollToBottom={handleScroll} // Carrega mais itens ao rolar
        classNamePrefix="select2-selection"
        placeholder="Digite para buscar empresas"
        isClearable={true}
      />
    </div>
  );
};


export const InputEmailUsuario = ({ onResult, onReset }) => {
  const [email, setEmail] = useState('');
  const [usuario, setUsuario] = useState({ ID_Usuario: '', Nome_Completo: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isVerified, setIsVerified] = useState(false); // Para mudar o ícone para check verde
  const [cookie] = useCookies(['_csrf_token']);

  // Limpa o estado do usuário ao digitar um novo e-mail
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsVerified(false);  // Resetar a verificação ao alterar o email
    setError(null);  // Limpa o erro quando o valor muda
    setUsuario({ ID_Usuario: '', Nome_Completo: '' });
    onReset(); // Reseta o formulário no componente pai
  };

  const handleSearch = async () => {
    if (!email) {
      setError('Email não pode estar vazio.');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
        proc: 'FRONT_Permissao_Obter_Usuario',
        requestData: { "Email": email }
      }, {
        headers: {
          "X-CSRF-Token": cookie?._csrf_token?.csrfToken
        }
      });

      if (response.data && response.data.length > 0) {
        const { ID_Usuario, Nome_Completo } = response.data[0];
        setUsuario({ ID_Usuario, Nome_Completo });
        onResult({ ID_Usuario, Nome_Completo, Email: email });
        setIsVerified(true); // Marca o ícone como verificado
      } else {
        setError('Nenhum usuário encontrado com este email.');
        setIsVerified(false); // Não marca o ícone
      }
    } catch (err) {
      console.error("Erro ao buscar usuário:", err);
      setError('Erro ao buscar usuário. Tente novamente.');
      setIsVerified(false); // Não marca o ícone
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormGroup>
      <Label for="Email">Email</Label>
      <InputGroup>
        <Input
          type="email"
          name="Email"
          id="Email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Digite o email"
          required
        />
        <InputGroupText onClick={handleSearch} style={{ cursor: 'pointer' }}>
          {isVerified ? (
            <i className="fas fa-check" style={{ color: 'green' }}></i>
          ) : (
            <i className="fas fa-search"></i>
          )}
        </InputGroupText>
      </InputGroup>
      {loading && <p>Carregando usuário...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
     {usuario.ID_Usuario && (
        <FormGroup><br/>
          <Label for="Usuario">Usuário</Label>
          <Input
            type="text"
            id="Usuario"
            value={`${usuario.ID_Usuario} - ${usuario.Nome_Completo}`}
            disabled
          />
        </FormGroup>
      )}
    </FormGroup>
  );
};

export const ComboboxEmpresaParceira = ({ ID_Usuario, onChange }) => {
  const [cookie] = useCookies(['_csrf_token']);
  const [allOptions, setAllOptions] = useState([]);
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [displayCount, setDisplayCount] = useState(10); // Inicialmente exibe 10 itens

  useEffect(() => {
    // Carrega todos os itens de uma vez quando o componente é montado
    const fetchAllOptions = async () => {
      try {
        const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
          proc: 'FRONT_Permissao_Obter_Empresa_Parceira',
          requestData: { "ID_Usuario": ID_Usuario, query: '' } // Remove a paginação e carrega todos os dados
        }, {
          headers: {
            "X-CSRF-Token": cookie?._csrf_token?.csrfToken
          }
        });

        const empresasOptions = response.data.map((empresa) => ({
          value: empresa.ID_Empresa_Parceira,
          label: empresa.Empresa_Parceira,
        }));

        setAllOptions(empresasOptions); // Salva todos os resultados
        setDisplayedOptions(empresasOptions.slice(0, displayCount)); // Inicialmente mostra apenas os primeiros 10 itens
      } catch (error) {
        console.error("Erro ao carregar empresas parceiras:", error);
      }
    };

    fetchAllOptions();
  }, [ID_Usuario]);

  const loadOptions = (inputValue, callback) => {
    if (inputValue) {
      const filteredOptions = allOptions.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    } else {
      callback(displayedOptions);
    }
  };

  const handleScroll = () => {
    const nextCount = displayCount + 10;
    const newOptions = allOptions.slice(0, nextCount);
    setDisplayedOptions(newOptions);
    setDisplayCount(nextCount);
  };

  return (
    <div className="mb-3 templating-select select2-container">
      <AsyncSelect
        cacheOptions
        defaultOptions={displayedOptions} // Mostra apenas os itens carregados atualmente
        loadOptions={loadOptions}
        onChange={onChange}
        onMenuScrollToBottom={handleScroll} // Carrega mais itens ao rolar
        classNamePrefix="select2-selection"
        placeholder="Digite para buscar empresas parceiras"
        isClearable={true}
      />
    </div>
  );
};



export const SwitchVisivel = ({ isChecked, onChange }) => {
  return (
    <div className="form-check form-switch mb-3">
      <input
        type="checkbox"
        className="form-check-input"
        id="switchVisivel"
        checked={isChecked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor="switchVisivel">
        Visível
      </label>
    </div>
  );
};

// Componente para o switch de Ativo
export const SwitchAtivo = ({ isChecked, onChange }) => {
  return (
    <div className="form-check form-switch mb-3">
      <input
        type="checkbox"
        className="form-check-input"
        id="switchAtivo"
        checked={isChecked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor="switchAtivo">
        Ativo
      </label>
    </div>
  );
};


export const ComboboxDocumento = ({ ID_Documento_Grupo, onChange }) => {
  const [cookie] = useCookies(['_csrf_token']);
  const [allOptions, setAllOptions] = useState([]);
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [displayCount, setDisplayCount] = useState(10); // Inicialmente exibe 10 itens
  const [selectedOption, setSelectedOption] = useState(null); // Valor inicial nulo
  const [loading, setLoading] = useState(false); // Inicialmente, não está carregando
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllOptions = async () => {
      if (!ID_Documento_Grupo) return; // Se o grupo não estiver selecionado, não faz a busca

      setLoading(true); // Ativa o carregamento quando for buscar os documentos
      try {
        const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
          proc: 'FRONT_Permissao_Obter_Documento',
          requestData: { ID_Documento_Grupo: ID_Documento_Grupo } // Filtra os documentos com base no grupo selecionado
        }, {
          headers: {
            "X-CSRF-Token": cookie?._csrf_token?.csrfToken
          }
        });

        const documentos = response.data.map((doc) => ({
          value: doc.ID_Documento,
          label: doc.Documento,
        }));

        setAllOptions(documentos); // Salva todos os resultados
        setDisplayedOptions(documentos.slice(0, displayCount)); // Inicialmente mostra apenas os primeiros 10 itens

        // Se houver documentos, seleciona automaticamente o primeiro
        if (documentos.length > 0) {
          const firstOption = documentos[0];
          setSelectedOption(firstOption); // Seleciona o primeiro documento
          onChange({ target: { value: firstOption.value } }); // Chama o onChange para o documento inicial
        }
      } catch (err) {
        console.error("Erro ao carregar documentos:", err);
        setError('Erro ao carregar documentos');
      } finally {
        setLoading(false); // Finaliza o carregamento
      }
    };

    fetchAllOptions(); // Chama a função de busca apenas se `ID_Documento_Grupo` estiver presente
  }, [ID_Documento_Grupo, cookie, displayCount, onChange]);

  const loadOptions = (inputValue, callback) => {
    if (inputValue) {
      const filteredOptions = allOptions.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    } else {
      callback(displayedOptions);
    }
  };

  const handleScroll = () => {
    const nextCount = displayCount + 10;
    const newOptions = allOptions.slice(0, nextCount);
    setDisplayedOptions(newOptions);
    setDisplayCount(nextCount);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange({ target: { value: selectedOption ? selectedOption.value : "" } });
  };

  // Exibe a mensagem de carregamento ou erro se aplicável
  if (loading) {
    return <p>Carregando documentos...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <FormGroup>
      <Label for="Documento">Documento</Label>
      <AsyncSelect
        cacheOptions
        defaultOptions={displayedOptions} // Mostra apenas os itens carregados atualmente
        loadOptions={loadOptions}
        onChange={handleSelectChange}
        onMenuScrollToBottom={handleScroll} // Carrega mais itens ao rolar
        placeholder="Selecione um Documento"
        isClearable
        value={selectedOption} // Define o valor selecionado
        isDisabled={!ID_Documento_Grupo || allOptions.length === 0}  // Desabilita o combobox se nenhum grupo for selecionado ou não houver opções
      />
    </FormGroup>
  );
};


export const ComboboxIntercambio_direto = ({ onChange }) => {
  const [cookie] = useCookies(['_csrf_token']);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length < 3) {
      return callback([]); // Retorna um array vazio se menos de 3 letras forem digitadas
    }

    try {
      const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
        proc: 'FRONT_Permissao_Obter_Intercambio',
        requestData: {"1": inputValue }
      }, {
        headers: {
          "X-CSRF-Token": cookie?._csrf_token?.csrfToken
        }
      });

      const options = response.data.map((inter) => ({
        value: inter.ID_Intercambio,
        label: inter.Intercambio,
      }));

      callback(options);
    } catch (err) {
      console.error("Erro ao carregar intercâmbios:", err);
      callback([]); // Retorna um array vazio em caso de erro
    }
  };

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      onChange(selectedOption);  // Passa o objeto selecionado adiante
    } else {
      onChange(null);  // Trata o caso de seleção ser desmarcada (se `isClearable` estiver ativado)
    }
  };

  return (
    <FormGroup>
      <Label for="Intercambio">Intercâmbio por digitacao</Label>
      <AsyncSelect
        cacheOptions
        defaultOptions={false}  // Busca só após digitar 3 letras
        loadOptions={loadOptions}
        onChange={handleChange}
        placeholder="Digite ao menos 3 letras para buscar"
        isClearable
        minInputLength={3}  // Configura a busca para começar após 3 letras
      />
    </FormGroup>
  );
};


export const ComboboxIntercambio_consulta = ({ onChange }) => {
  const [cookie] = useCookies(['_csrf_token']);
  const [inputValue, setInputValue] = useState('');
  const [optionsCache, setOptionsCache] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null); // Valor inicial padrão nulo
  const [isLocked, setIsLocked] = useState(false);

  const loadOptions = async (inputValue, callback) => {
    // Se o usuário digitar exatamente 3 letras, faz uma nova requisição à API
    if (inputValue.length === 3) {
      try {
        const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
          proc: 'FRONT_Permissao_Obter_Intercambio',
          requestData: { query: inputValue }
        }, {
          headers: {
            "X-CSRF-Token": cookie?._csrf_token?.csrfToken
          }
        });

        const options = response.data.map((inter) => ({
          value: inter.ID_Intercambio,
          label: inter.Intercambio,
        }));

        setOptionsCache(options); // Atualiza o cache com os novos dados
        callback(options);
        setIsLocked(true); // Travar novas requisições até que o usuário digite menos de 3 letras novamente

        // Se houver opções, seleciona automaticamente a primeira
        if (options.length > 0) {
          setSelectedOption(options[0]);
          onChange(options[0].value); // Define a primeira opção como selecionada
        }

      } catch (err) {
        console.error("Erro ao carregar intercâmbios:", err);
        callback([]);
      }
    } 
    // Se o usuário digitar mais de 3 letras, faz a filtragem local usando o cache
    else if (inputValue.length > 3) {
      const filteredOptions = optionsCache.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    } 
    // Se tiver menos de 3 letras, limpa as opções
    else {
      callback([]);
      setIsLocked(false); // Libera o bloqueio de novas buscas quando o número de letras diminui
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption ? selectedOption.value : '');
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%'
    }),
    control: (provided) => ({
      ...provided,
      width: '100%'
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: '100%'
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%'
    })
  };

  return (
    <FormGroup>
      <Label for="Intercambio">Intercâmbio 3 letras</Label>
      <InputGroup style={{ width: '100%' }}>
        <AsyncSelect
          cacheOptions
          defaultOptions={optionsCache}
          loadOptions={loadOptions}
          onChange={handleChange}
          value={selectedOption} // Mantém a opção selecionada
          placeholder="Digite ao menos 3 letras"
          isClearable
          inputValue={inputValue}
          onInputChange={handleInputChange}
          styles={customStyles}
          required={false}
        />
      </InputGroup>
    </FormGroup>
  );
};



export const ComboboxGrupoDocumento = ({ onChange }) => {
  const [cookie] = useCookies(['_csrf_token']);
  const [allOptions, setAllOptions] = useState([]);
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [displayCount, setDisplayCount] = useState(10); // Inicialmente exibe 10 itens

  useEffect(() => {
    const fetchAllOptions = async () => {
      try {
        const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
          proc: 'FRONT_Permissao_Obter_Grupo_Documento',
          requestData: { query: '' } 
        }, {
          headers: {
            "X-CSRF-Token": cookie?._csrf_token?.csrfToken
          }
        });

        const gruposDocumento = response.data.map((doc) => ({
          value: doc.ID_Documento_Grupo,
          label: doc.Grupo_de_Documentos,
        }));

        setAllOptions(gruposDocumento); // Salva todos os resultados
        setDisplayedOptions(gruposDocumento.slice(0, displayCount)); // Inicialmente mostra apenas os primeiros 10 itens
      } catch (err) {
        console.error("Erro ao carregar grupos de documentos:", err);
      }
    };

    fetchAllOptions();
  }, []);

  const loadOptions = (inputValue, callback) => {
    if (inputValue) {
      const filteredOptions = allOptions.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    } else {
      callback(displayedOptions);
    }
  };

  const handleScroll = () => {
    const nextCount = displayCount + 10;
    const newOptions = allOptions.slice(0, nextCount);
    setDisplayedOptions(newOptions);
    setDisplayCount(nextCount);
  };

  return (
    <FormGroup>
      <Label for="GrupoDocumento">Grupo Documento</Label>
      <AsyncSelect
        cacheOptions
        defaultOptions={displayedOptions} // Mostra apenas os itens carregados atualmente
        loadOptions={loadOptions}
        onChange={onChange}
        onMenuScrollToBottom={handleScroll} // Carrega mais itens ao rolar
        placeholder="Selecione um Grupo de Documento"
        isClearable
      />
    </FormGroup>
  );
};


export const ComboboxEmpresaPlanta = ({ ID_Empresa, onChange }) => {
  const [plantas, setPlantas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cookie] = useCookies(['_csrf_token']);
  const [selectedOption, setSelectedOption] = useState(""); // Valor inicial padrão vazio

  useEffect(() => {
    const fetchPlantas = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
          proc: 'FRONT_Permissao_Obter_Planta',
          requestData: { "ID_Empresa": ID_Empresa }
        }, {
          headers: {
            "X-CSRF-Token": cookie?._csrf_token?.csrfToken
          }
        });

        setPlantas(response.data);

        // Define a primeira opção como selecionada, apenas se uma opção não foi selecionada ainda
        if (response.data.length > 0) {
          const firstOption = response.data[0].ID_Planta;
          setSelectedOption(firstOption);
          onChange({ target: { value: firstOption } }); // Chama o onChange para enviar o valor inicial
        }
      } catch (err) {
        console.log("Erro ao carregar plantas:", err);
        setError('Erro ao carregar plantas');
      } finally {
        setLoading(false);
      }
    };

    if (ID_Empresa) {
      fetchPlantas();
    } else {
      setPlantas([]);
      setSelectedOption(""); // Reseta a seleção se não houver empresa
    }
  }, [ID_Empresa, cookie]); // Somente refazer o efeito quando ID_Empresa mudar

  if (loading) {
    return <p>Carregando plantas...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    onChange(e); // Chama o onChange para atualizar o valor selecionado no componente pai
  };

  return (
    <Input type="select" value={selectedOption} onChange={handleSelectChange} required>
      {plantas.map((planta) => (
        <option key={planta.ID_Planta} value={planta.ID_Planta}>
          {planta.Planta}
        </option>
      ))}
    </Input>
  );
};


export const ComboboxPlantaParceira = ({ ID_Empresa_Parceira, onChange }) => {
  const [plantasParceiras, setPlantasParceiras] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cookie] = useCookies(['_csrf_token']);
  const [selectedOption, setSelectedOption] = useState(""); // Valor inicial padrão vazio

  useEffect(() => {
    const fetchPlantasParceiras = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
          proc: 'FRONT_Permissao_Obter_Planta_Parceira',
          requestData: { "ID_Empresa": ID_Empresa_Parceira }
        }, {
          headers: {
            "X-CSRF-Token": cookie?._csrf_token?.csrfToken
          }
        });

        const plantas = response.data.map((planta) => ({
          ID_Planta_Parceira: planta.ID_Planta_Parceira,
          Planta_Parceira: planta.Planta_Parceira,
        }));

        setPlantasParceiras(plantas); // Salva as opções recebidas do servidor

        // Define a primeira opção como selecionada, apenas se uma opção não foi selecionada ainda
        if (plantas.length > 0) {
          const firstOption = plantas[0].ID_Planta_Parceira;
          setSelectedOption(firstOption);
          onChange({ target: { value: firstOption } }); // Chama o onChange para enviar o valor inicial
        }
      } catch (err) {
        console.log("Erro ao carregar plantas parceiras:", err);
        setError('Erro ao carregar plantas parceiras');
      } finally {
        setLoading(false);
      }
    };

    if (ID_Empresa_Parceira) {
      fetchPlantasParceiras();
    } else {
      setPlantasParceiras([]);
      setSelectedOption(""); // Reseta a seleção se não houver empresa parceira
    }
  }, [ID_Empresa_Parceira, cookie]); // Somente refazer o efeito quando ID_Empresa_Parceira mudar

  if (loading) {
    return <p>Carregando plantas parceiras...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    onChange(e); // Chama o onChange para atualizar o valor selecionado no componente pai
  };

  return (
    <Input type="select" value={selectedOption} onChange={handleSelectChange} required>
      {plantasParceiras.map((planta) => (
        <option key={planta.ID_Planta_Parceira} value={planta.ID_Planta_Parceira}>
          {planta.Planta_Parceira}
        </option>
      ))}
    </Input>
  );
};
