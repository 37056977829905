import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import config from "../../config"; // Certifique-se de que o caminho esteja correto
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

const RecoverPassword = () => {
    document.title = "Recover Password | Upzet - React Admin & Dashboard Template";

    // Estado para email, IP, e mensagem
    const [email, setEmail] = useState('');
    const [ip, setIp] = useState('');
    const [message, setMessage] = useState('Insira seu Email e as instruções serão enviadas para você!');
    const [messageType, setMessageType] = useState('warning'); // 'success' or 'warning'
    const [messageVisible, setMessageVisible] = useState(true); // Para controlar a visibilidade da mensagem
    const [loadingIp, setLoadingIp] = useState(true); // Para controlar o carregamento do IP
    const [cookie] = useCookies(['_csrf_token']);

    // Função para obter o IP do usuário
    const fetchIp = async () => {
        try {
            const response = await axios.get(`${config.URL_SERVER}/api/get-ip`);
            setIp(response.data.ip);
        } catch (error) {
            console.error("Erro ao obter IP:", error);
        } finally {
            setLoadingIp(false); // IP carregado (sucesso ou erro)
        }
    };

    // Obter o IP ao carregar o componente
    useEffect(() => {
        fetchIp();
    }, []);

    // Função para enviar a solicitação de redefinição de senha
    const handlePasswordReset = async (e) => {
        e.preventDefault();
        
        if (!email){
           
            setMessageType('warning');
            setMessageVisible(true);
            return;
        }

        try {
            const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
                proc: 'FRONT_Usuario_Redefinir_Senha',
                requestData: { "email": email, "ip": ip }
            }, {
                headers: {
                    "X-CSRF-Token": cookie?._csrf_token?.csrfToken
                }
            });
            
           // console.log('response.data', response.data.resultset_2);

            // Tratar o resultset_2
            const result = response.data?.resultset_2?.[0];


            if (result && result.statusCode === 200) {
                setMessage(
                    <>
                        {result.message} <Link to="/login" className="text-primary"><strong>Voltar para o Login</strong></Link>
                    </>
                );                
                setMessageType('success');
            } else {
                setMessage('Erro ao redefinir a senha. Por favor, tente novamente.');
                setMessageType('warning');
            }
        } catch (error) {
            console.error("Erro ao enviar email:", error);
            setMessage('Erro ao enviar o email. Por favor, tente novamente.');
            setMessageType('warning');
        } finally {
            setMessageVisible(true);
        }
    };

    return (
        <React.Fragment>
            <div className="bg-pattern" style={{height:"100vh"}}>
                <div className="bg-overlay"></div>
                <div className="account-pages pt-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6} md={8} xl={4}>
                                <Card className='mt-5'>
                                    <CardBody className="p-4">
                                        <div className="">
                                            <div className="text-center">
                                                 <Link to="/" className="">
                                                    <img src={logodark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                                                    <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                                                 </Link>
                                            </div>
                                            <h4 className="font-size-18 text-muted mt-2 text-center">Reset Senha</h4>
                                            <p className="mb-5 text-center"></p>
                                            <form className="form-horizontal" onSubmit={handlePasswordReset}>
                                                <Row>
                                                    <Col md={12}>
                                                        {messageVisible && (
                                                            <div className={`alert alert-${messageType} alert-dismissible`}>
                                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMessageVisible(false)}></button>
                                                                {message}
                                                            </div>
                                                        )}
                                                        <div className="mt-4">
                                                            <label className="form-label" htmlFor="useremail">Email</label>
                                                            <input 
                                                                type="email" 
                                                                className="form-control" 
                                                                id="useremail" 
                                                                placeholder="Enter email" 
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="d-grid mt-4">
                                                            <button 
                                                                className="btn btn-primary waves-effect waves-light" 
                                                                type="submit" 
                                                                disabled={loadingIp} // Desabilita o botão enquanto o IP está carregando
                                                            >
                                                                {loadingIp ? 'Carregando...' : 'Enviar Email'}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p className="text-white-50">Don't have an account ?  <Link to="/auth-register" className="fw-medium text-primary"> Register  </Link> </p>
                                    <p className="text-white-50">© {new Date().getFullYear()} Upzet. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RecoverPassword;
