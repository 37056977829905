import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input, FormGroup, Label } from 'reactstrap';
import DataTable from 'react-data-table-component';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom'

import makeAnimated from "react-select/animated";

import { formatDate, getBrazilianTime, formatDateToBrazilian, formatDateToBrazilian_filtro, handleToggle } 
from '../../components/Utils/formatDate';


import { 
  Card, CardBody, Col, Container, Row, 
  Spinner, 
  Dropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Toast, ToastHeader
} from 'reactstrap';


import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from 'axios';

import config from "../../config";
import { useCookies } from "react-cookie";

const Permissao = () => {

  document.title = "Basic Tables | Upzet - React Admin & Dashboard Template";

  const navigate = useNavigate()
  
  const [selectedRow, setSelectedRow] = useState(null);
  const [isFiltrosOpen, setIsFiltrosOpen] = useState(false);
  const [cookie] = useCookies(['_csrf_token']);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [data, setData] = useState([]); // Estado de dados
  const [loading, setLoading] = useState(true);  
  const [toastDataRange, setToastDataRange] = useState(true);
  const [ID_Usuario, setID_Usuario] = useState('');
  
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const animatedComponents = makeAnimated();

  const optionGroup2 = [
    { label: "Mustard", value: "Mustard" },
    { label: "Ketchup", value: "Ketchup" },
    { label: "Relish", value: "Relish" },
    { label: "Tent", value: "Tent" },
    { label: "Flashlight", value: "Flashlight" },
    { label: "Toilet Paper", value: "Toilet Paper" }
  ];

  const handleInsertPermissao = (newPermissao) => {
  // Lógica para lidar com a inserção de uma nova permissão
  console.log('Nova permissão inserida:', newPermissao);
  // Talvez fazer uma chamada de API para inserir a nova permissão
  };

  const toggleFiltros = () => {
    setIsFiltrosOpen(!isFiltrosOpen);
  };

  const toggleDropdown = (id) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const toggleToastDataRange = () => setToastDataRange(!toastDataRange);

  const handlerFilter = (params) => {
    const adjustedParams = {
      ...params,
      Ativo: params.Ativo === 'Todas' ? null : params.Ativo,
      PreFiltro: params.PreFiltro === 'Todas' ? null : params.PreFiltro,
    };
    setIsFiltrosOpen(!isFiltrosOpen);
    fetchData(adjustedParams);
  }

  const refreshTable = async () => {   
    const params = {    
      ID_Usuario: ID_Usuario
    };
    fetchData(params);
  };

  const columns = [
    {
      name: 'ID Permissão',
      selector: row => row.ID_Permissao,
      sortable: true,
    },  
    {
      name: 'Ativo',
      selector: row => (
        <div className="form-check form-switch form-switch">
          <input
            type="checkbox"
            className="form-check-input"
            id={`customSwitch_${row.ID_Permissao}`}
            checked={row.Ativo === 'S'}
            onChange={() => handleToggle(row.ID_Permissao)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'ID Usuário',
      selector: row => row.ID_Usuario,
      sortable: true,
    },
    {
      name: 'Usuário',
      selector: row => row.Usuario,
      sortable: true,
    },
    {
      name: 'Empresa',
      selector: row => row.Empresa,
      sortable: true,
    },
    {
      name: 'Planta',
      selector: row => row.Planta,
      sortable: true,
    },
    {
      name: 'Empresa Parceira',
      selector: row => row.Empresa_Parceira,
      sortable: true,
    },
    {
      name: 'Planta Parceira',
      selector: row => row.Planta_Parceira,
      sortable: true,
    },
    {
      name: 'Documento',
      selector: row => row.Documento,
      sortable: true,
    },
    {
      name: 'Grupo Documentos',
      selector: row => row.Grupo_Documentos,
      sortable: true,
    },
    {
      name: 'Intercambio',
      selector: row => row.Intercambio,
      sortable: true,
    },
    {
      name: 'Visível',
      selector: row => row.Visivel,
      sortable: true,
    }
  ];

  const fetchData = async (params) => {
    try {
      setLoading(true);  
      const nomeProcedimento = "FRONT_Permissao_Obter_Lista";

      let response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
        requestData: params,
        proc: nomeProcedimento
      },{
        headers: {
          "X-CSRF-Token": cookie?._csrf_token?.csrfToken
        }
      });

      setID_Usuario(params.ID_Usuario);

      console.log("Dados recebidos da API (array):", response.data);
      
      // Atualizando o estado 'data' com os dados recebidos
      setData(response.data);

    } catch (error) {
      console.error("Erro ao buscar dados: ", error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser && authUser.ID_Usuario) {

      setID_Usuario(authUser.ID_Usuario); // Seta o ID_Usuario a partir do localStorage
      fetchData({ ID_Usuario: authUser.ID_Usuario }); // Chama a função para buscar dados com o ID do usuário
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Tables" breadcrumbItem="Permissões" />
          <Row>


           

            <Col lg={12}>
              <Card>
                <CardBody>
                   <p className="card-title-desc"></p>
                                  
                  <div>
                 <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => navigate('/permissao-insert')}
                  >
                    <i className="mdi mdi-plus"></i>
                    Permissão
                  </button>
                                  
                  </div>    
                  {loading ? (
                    <Spinner color="primary" size="md">
                      Carregando...
                    </Spinner>
                  ) : data.length ? (
                     <div className="data-table-container">
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination={true}
                          title={""}
                          noDataComponent={"Nenhum registro encontrado"}
                        />
                      </div>
                  ) : (
                    <div className="text-center">
                      <p>Sem dados para essa consulta...</p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Permissao;
