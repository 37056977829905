import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// import sidebarData from "./SidebarData";
//Simple bar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../../components/Common/withRouter";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import axios from "axios";
import config from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { changeMenuActived } from "../../store/actions";
import { Spinner } from "reactstrap";
import { useCookies } from "react-cookie";

const randomIcones = [
  'bx bx-network-chart',
  'bx bx-calendar-week',
  'bx bx-layer-plus',
  'bx bx-shape-square',
  'bx bx-store-alt',
  'bx bx-disc',
  'bx bx-dollar-circle',
  'bx bx-cart-alt',
  'bx bx-world',
  'bx bx-news',
  'bx bx-trophy',
  'bx bx-shield-quarter',
  'bx bx-layer',
  'bx bx-chart',
  'bx bx-bar-chart-square',
  'bx bxs-door-open',
  'bx bxs-doughnut-chart',
  'bx bx-cart-alt',
  'bx bx-world',
  'bx bx-news',
  'bx bx-trophy',
  'bx bx-shield-quarter',
  'bx bx-layer',
  'bx bx-chart',
  'bx bx-bar-chart-square',
  'bx bxs-door-open',
  'bx bxs-doughnut-chart'
]

const Sidebar = (props) => {
  const ref = useRef();
  const [menuList, setMenuList] = useState([])
  const [cookie, setCookie] = useCookies(['_csrf_token'])
  const dispatch = useDispatch()
  const { menuActived } = useSelector(state => ({
    menuActived: state.Layout.menuActived.menu
  }))

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // const removeActivation = items => {
  //   for (var i = 0; i < items.length; ++i) {
  //     var item = items[i];
  //     const parent = items[i].parentElement;
  //     if (item && item.classList.contains("active")) {
  //       item.classList.remove("active");
  //     }
  //     if (parent) {
  //       const parent2El =
  //         parent.childNodes && parent.childNodes.length && parent.childNodes[1]
  //           ? parent.childNodes[1]
  //           : null;
  //       if (parent2El && parent2El.id !== "side-menu") {
  //         parent2El.classList.remove("mm-show");
  //       }
  //       parent.classList.remove("mm-active");
  //       const parent2 = parent.parentElement;
  //       if (parent2) {
  //         parent2.classList.remove("mm-show");
  //         const parent3 = parent2.parentElement;
  //         if (parent3) {
  //           parent3.classList.remove("mm-active"); // li
  //           parent3.childNodes[0].classList.remove("mm-active");
  //           const parent4 = parent3.parentElement; // ul
  //           if (parent4) {
  //             parent4.classList.remove("mm-show"); // ul
  //             const parent5 = parent4.parentElement;
  //             if (parent5) {
  //               parent5.classList.remove("mm-show"); // li
  //               parent5.childNodes[0].classList.remove("mm-active"); // a tag
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  const activeMenu = useCallback(() => {
    const pathName = props.router.location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu-item");
    const items = ul.getElementsByTagName("a");
    // removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        dispatch(changeMenuActived({menu: items[i].ariaLabel, id: items[i].getAttribute('data-id')}))
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [
    props.router.location.pathname,
    // activateParentDropdown,
  ]);

  const getMenuList = async () => {
    let response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
      requestData: {
        1: "2415"
      },
      proc: 'FRONT_Framework_Obter_Menu_Aplicacoes'
    }, {
      headers: {
        "X-CSRF-Token": cookie?._csrf_token?.csrfToken
      }
    });
    
    let dataMenus = response.data
console.log('dataMenus' , dataMenus);
/*{
    "ID_Aplicacao": 9,
    "Descricao_Aplicacao": "Taskman",
    "Chave_Aplicacao": "APP_TASKMAN",
    "Icone": "fas fa-tasks",
    "Disponivel": "S",
    "Url": "/taskman"
}*/

 // Adicionando manualmente a opção "Permissão"
  dataMenus.push({
    Descricao_Aplicacao: "Permissão",
    Chave_Aplicacao: "PERMISSAO",
    Icone: "bx bx-shield-quarter",
    Disponivel: "S",
    Url: "/permissao-insert"
  });

 // Adicionando manualmente a opção "Permissão"
  dataMenus.push({
    Descricao_Aplicacao: "Permissão",
    Chave_Aplicacao: "PERMISSAO",
    Icone: "bx bx-shield-quarter",
    Disponivel: "S",
    Url: "/permissao"
  });


    dataMenus.unshift(
    //   {
    //   Descricao: "Dashboard",
    //   icon: "mdi mdi-home-variant-outline",
    //   url: "/dashboard",
    //   issubMenubadge: true,
    //   bgcolor: "bg-primary",
    //   badgeValue: "3",
    //   isActive: true
    // },
    ...( process.env.NODE_ENV === "development" ? [{
      Descricao_Aplicacao: "Ui Lab",
      Chave_Aplicacao: "UI_LABS",
      Icone: "bx bx-star",
      Disponivel: "S",
      url: "/ui",
      issubMenubadge: true,
      bgcolor: "bg-primary",
      badgeValue: "3"
    }
  ] : []))

    setMenuList(dataMenus)
  }

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu-item");
    getMenuList();
    activeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">          
            <ul className="metismenu list-unstyled" id="side-menu-item">
              {menuList.length ? 
              <>
                {menuList.map((item, key) => (
                  <React.Fragment key={key}>
                    {/* {item.isMainMenu ? (
                      <></>
                      <li className="menu-title">{props.t(item.label)}</li>
                    ) : ( */}
                      <li key={key} className={`
                        ${(item.Disponivel == 'N' ? 'disabled' : '')}
                        ${(menuActived == item?.Chave_Aplicacao) ? 'mm-active'  : ''}`}>
                        <Link
                          to={item.Url ? item.Url : "/#"}
                          //to={'/' + item.Descricao_Aplicacao}
                          aria-label={item?.Chave_Aplicacao || ''}
                          data-id={item?.ID_Aplicacao || ''}
                          className={`${(menuActived == item?.Chave_Aplicacao) ? 'active' : ''}`}
                        >
                          <div className="icone">
                            <i className={item?.Icone || randomIcones[key]}></i>
                          </div>
                          {item.issubMenubadge && (
                            <span
                              className={
                                "badge rounded-pill float-end " + item.bgcolor
                              }
                            >
                              {" "}
                              {item.badgeValue}{" "}
                            </span>
                          )}
                          <span>{props.t(item.Descricao_Aplicacao)}</span>
                        </Link>
                        {/* {item.subItem && (
                          <ul className="sub-menu">
                            {item.subItem.map((item, key) => (
                              <li key={key}>
                                <Link
                                  to={item.link}
                                  className={
                                    item.subMenu && "has-arrow waves-effect"
                                  }
                                >
                                  {props.t(item.sublabel)}
                                </Link>
                                {item.subMenu && (
                                  <ul className="sub-menu">
                                    {item.subMenu.map((item, key) => (
                                      <li key={key}>
                                        <Link to="#">
                                          {props.t(item.title)}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        )} */}
                      </li>
                    {/* )} */}
                  </React.Fragment>
                ))}
                                
              </>
              : 
              <Spinner color="primary" size="md">
                  Carregando...
              </Spinner>
              }
            </ul>    
          </div> 
        </SimpleBar>
      </div>
    </React.Fragment>
  );
};
Sidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Sidebar));