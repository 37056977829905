import axios from "axios";
import config from "../config";

const _serverUrl = `${config.URL_SERVER}`;

const api = axios.create({
  baseURL: _serverUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    "Access-Control-Expose-Headers": "*"
  }
});
 
 //password: btoa(password),

const BizinBackend = {
  async loginUser(email, password, _csrf) {
      const url = `${_serverUrl}/login`;
      const data = {
        _csrf,  
        email,       
        password: password,
        proc: "FRONT_Usuario_Verificar_Login",
      };

      const response = (await api.post(url, data)).data
      
      if(response.data) return {...response.data.resultset_1[0], access_token: response.access_token}
      else return;
  },
  async checkAccessToken(access_token) {
    const url = `/check-access-token`;
    return await api.post(url, { _ac_token: access_token })
  },
};

export default BizinBackend;