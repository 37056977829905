import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import ReactDOM from 'react-dom';

import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min.css';

import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap";

const Filtros = ({ isOpen, onClose, callbackFilter = () => {} }) => {
  const ref = useRef();
  const selectRef = useRef(null);
  const textInputRef = useRef(null); // Para o campo de texto com Choices.js
  const [inputs, setInputs] = useState({
    nome_fantasia: ''
  })

  const submitFilter = () => {
    console.log('FNFiltros')
    callbackFilter(inputs)
  }

  useEffect(() => {
    if (isOpen && ref.current) {
      ref.current.recalculate();
    }

   let selectChoices;
    if (isOpen && selectRef.current) {
      selectChoices = new Choices(selectRef.current, {
        removeItemButton: true,
        maxItemCount: 5,
        searchEnabled: true,
        // Outras opções conforme necessário
      });
    }

       // Inicialização do Choices.js para o campo de texto
    // let textChoices;
    // if (isOpen && textInputRef.current) {
    //   textChoices = new Choices(textInputRef.current, {
    //     delimiter: ',',  // Delimitador para separar os itens
    //     editItems: true, // Permite editar os itens
    //     maxItemCount: 5, // Número máximo de itens
    //     removeItemButton: true, // Permite a remoção de um item
    //     addItems: true,  // Permite adicionar novos itens
    //     paste: false,    // Desabilita colar texto
    //   });
    // }

     return () => {
      if (selectChoices) {
        selectChoices.destroy();
      }
      // if (textChoices) {
      //   textChoices.destroy();
      // }
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <React.Fragment>
      <div style={{ width: '390px'}} className="right-bar" id="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                className="right-bar-toggle float-end" onClick={onClose}
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Filtros</h5>
            </div>

            <hr className="my-0" />

          </div>
          <div className="p-4">
            
               <Row>
                <Col md={1} className="col-form-label">
                  <label htmlFor="date-input-from">
                    De
                  </label>
                </Col>
                <Col md={5}>
                  <input
                    className="form-control"
                    type="date"
                    defaultValue="2019-08-19"
                    id="date-input-from"
                  />
                </Col>
                <Col md={1} className="col-form-label">
                  <label htmlFor="date-input-to">
                    até
                  </label>
                </Col>
                <Col md={5}>
                  <input
                    className="form-control"
                    type="date"
                    defaultValue="2019-08-19"
                    id="date-input-to"
                  />
                </Col>
              </Row>
             
              <Row>
               <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                  >
                    Nome Fantasia
                  </label>
                  <div className="col-md-12">
                    <input
                    //  ref={textInputRef}
                      onChange={e => {
                        setInputs({...inputs, nome_fantasia: e.target.value})
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Digite"
                    />
                  </div>
             </Row>

             {/* <Row>
              <Col md={12}>
               <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                  >
                    Empresa Solicitante
                  </label>
                <select ref={selectRef} multiple>
                  <option value="option1">Opção 1</option>
                  <option value="option2">Opção 2</option>
                  <option value="option3">Opção 3</option>
                </select>
              </Col>
            </Row> */}
          </div>

          <hr className="mt-1" />

          <div className="px-4">
            <Row>
              <Col md={12}>
                <button type="button" onClick={() => submitFilter()} className="btn btn-primary w-100">Filtrar</button>
              </Col>
            </Row>
          </div>
        </SimpleBar>
      </div>
      {ReactDOM.createPortal(
        <div className="rightbar-overlay"></div>,
        document.getElementById('layout-wrapper')
      )}
    </React.Fragment>
  );
}

export default Filtros;