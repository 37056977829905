import React, { useState } from "react";
import DropdownCustom from "../Utils/DropdownCustom";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { filterSubmenus } from "../../helpers/component_helpers";

const TopBarInside = () => {
    let {menus} = useSelector(state => ({
        menus: state.Layout.topbarInside.payload
    }))

    const originalMenus = menus

    menus = menus.filter(item => item?.ID_Menu_Pai === 0)

    const handlerSubmenus = (m, id_menu) => {
        const filter_only_submenus = filterSubmenus(m, id_menu)
        return filter_only_submenus
    }

  return (
    <React.Fragment>
      <div id="page-topbarInsideTopBarInside" className="d-flex align-items-center justify-content-center">
        <div className="navbar-container">
            <div className="items">
                {menus.length ? 
                    <>
                        {menus?.map((item, key) => (
                            <>
                                {item.Dropdown === 'S' ?
                                    <DropdownCustom originalDataMenus={originalMenus} className={item?.Disponivel == 'N' ? 'disabled' : ''} icon={item?.Icone} items={handlerSubmenus((item?.childrens?.length ? item?.childrens : originalMenus), item?.ID_Menu)} label={item?.Descricao || "Dropdown"} />
                                :  <Link key={key} to={item.Codigo_Transacao || ''} className={`btn btn-item-topbar-inside ${item?.Disponivel == 'N' ? 'disabled' : ''}`}>
                                        {item.Icone && <i className={item.Icone}></i>}
                                        <span className="d-none d-xl-inline-block">{item.Descricao}</span>
                                    </Link>
                                }
                            </>
                        ))}
                    </>
                : 
                <Spinner color="primary" size="md">
                    Carregando...
                </Spinner>}
            </div>
                {/* <div className="dropdown d-inline-block">
                    <button
                        type="button"
                        className="btn header-item noti-icon right-bar-toggle waves-effect"
                    >
                        <i className="mdi mdi-cog"></i>
                    </button>
                </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopBarInside;
