import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import ReactDOM from 'react-dom';
import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min.css';
import { Link } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";

const FiltrosTeste = ({ isOpen, onClose, callbackFilter = () => {}, initialFilter }) => {
  const ref = useRef();
  const selectRef = useRef(null);

  const getFirstDayOfMonth = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
  };

  const getCurrentDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  const [inputs, setInputs] = useState({
    Data_Inicio: getFirstDayOfMonth(),
    Data_Termino: getCurrentDate(),
    PreFiltro: initialFilter || 'NF' // Usar initialFilter se fornecido
  });

  const submitFilter = () => {
    callbackFilter(inputs);
  }

  useEffect(() => {
    if (isOpen && ref.current) {
      ref.current.recalculate();
    }

    let selectChoices;
    if (isOpen && selectRef.current) {
      selectChoices = new Choices(selectRef.current, {
        removeItemButton: true,
        maxItemCount: 5,
        searchEnabled: true,
      });
    }

    return () => {
      if (selectChoices) {
        selectChoices.destroy();
      }
    };
  }, [isOpen]);

  useEffect(() => {
    setInputs(prevInputs => ({
      ...prevInputs,
      PreFiltro: initialFilter || 'NF'
    }));
  }, [initialFilter]);

  if (!isOpen) {
    return null;
  }

  return (
    <React.Fragment>
      <div style={{ width: '390px' }} className="right-bar" id="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                className="right-bar-toggle float-end" onClick={onClose}
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Filtros Taskman</h5>
            </div>

            <hr className="my-0" />
          </div>
          <div className="p-4">
            <Row>
              <Col md={1} className="col-form-label">
                <label htmlFor="date-input-from">De</label>
              </Col>
              <Col md={5}>
                <input
                  className="form-control"
                  type="date"
                  id="date-input-from"
                  value={inputs.Data_Inicio}
                  onChange={e => setInputs({ ...inputs, Data_Inicio: e.target.value })}
                />
              </Col>
              <Col md={1} className="col-form-label">
                <label htmlFor="date-input-to">até</label>
              </Col>
              <Col md={5}>
                <input
                  className="form-control"
                  type="date"
                  id="date-input-to"
                  value={inputs.Data_Termino}
                  onChange={e => setInputs({ ...inputs, Data_Termino: e.target.value })}
                />
              </Col>
            </Row>
          </div>

          <hr className="mt-1" />

          <div className="px-4">
            <Row>
              <Col md={12}>
                <Button type="button" onClick={submitFilter} className="btn btn-primary w-100">Filtrar</Button>
              </Col>
            </Row>
          </div>
        </SimpleBar>
      </div>
      {ReactDOM.createPortal(
        <div className="rightbar-overlay"></div>,
        document.getElementById('layout-wrapper')
      )}
    </React.Fragment>
  );
}

export default FiltrosTeste;
