import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from 'axios'; // Importando o axios para fazer chamadas HTTP

//import Filtros from '../../Layout/VerticalLayout/Filtros'; // Ajuste o caminho conforme necessário

import Filtros from "../../components/Common/Filtros";
import config from "../../config";
import { useCookies } from "react-cookie";

const PowerBI = () => {
  document.title = "Power BI | Business Integrator";

  const [cookie, setCookie] = useCookies(['_csrf_token'])

  // Estado para armazenar os dados da API
  const [data, setData] = useState([]);

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Tables" breadcrumbItem="Demo Power BI" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">PowerBI 01</h4>
                  <iframe title="US Sales Analysis" width="100%" height="700" src="https://app.powerbi.com/reportEmbed?reportId=3c69142f-1568-4559-9515-5e633b4eda42&autoAuth=true&ctid=bf6b13fa-94be-4314-8d87-832dbe594b6d" frameborder="0" allowFullScreen="true"></iframe>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">PowerBI 02</h4>
                  <iframe title="Supply Chain Sample" width="100%" height="700" src="https://app.powerbi.com/reportEmbed?reportId=ac2c8b0a-0f37-4c9a-9fe4-d0dff421931f&autoAuth=true&ctid=bf6b13fa-94be-4314-8d87-832dbe594b6d" frameborder="0" allowFullScreen="true"></iframe>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">PowerBI 03</h4>
                  <iframe title="COVID Bakeoff" width="100%" height="700" src="https://app.powerbi.com/reportEmbed?reportId=086ef32c-1049-4b6a-8a12-81d25d9bee98&autoAuth=true&ctid=bf6b13fa-94be-4314-8d87-832dbe594b6d" frameborder="0" allowFullScreen="true"></iframe>
                </CardBody>
              </Card>
            </Col>
          </Row>

	  <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">PowerBI 04</h4>
                <iframe title="DASH_VENDAS_CASTELO_2024" width="100%" height="700" src="https://app.powerbi.com/reportEmbed?reportId=0df913a2-a440-428a-a443-c9e43f338cb2&autoAuth=true&ctid=bf6b13fa-94be-4314-8d87-832dbe594b6d" frameborder="0" allowFullScreen="true"></iframe>
	        </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PowerBI;
