// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: transparent;
    margin-bottom: 30px;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Ui/UiElements/masonry.css"],"names":[],"mappings":"AAAA,mBAC0B,gCAAgC,EAChC,gCAAgC;IACtD,aAAa;IACb,kBAAkB,EAAE,uBAAuB;IAC3C,WAAW;EACb;EACA;IACE,kBAAkB,EAAE,gBAAgB;IACpC,4BAA4B;EAC9B;;EAEA,qBAAqB;EACrB,gCAAgC,+DAA+D;IAC7F,uBAAuB;IACvB,mBAAmB;EACrB","sourcesContent":[".my-masonry-grid {\n    display: -webkit-box; /* Not needed if autoprefixing */\n    display: -ms-flexbox; /* Not needed if autoprefixing */\n    display: flex;\n    margin-left: -30px; /* gutter size offset */\n    width: auto;\n  }\n  .my-masonry-grid_column {\n    padding-left: 30px; /* gutter size */\n    background-clip: padding-box;\n  }\n  \n  /* Style your items */\n  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */\n    background: transparent;\n    margin-bottom: 30px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
