import React from "react";
import Routes from "./Routes/index";

// Import Scss
import "./assets/scss/theme.scss";
import { useSelector } from "react-redux";

function App() {
  const { Layout } = useSelector((state) => state);
  return (
    <React.Fragment>
      {Layout?.isLoading && (
        <div
          style={{
            backgroundColor: "#00000080",
            overflow: "hidden",
            color: "white",
            display: "flex",
            top: 0,
            padding: "6px 0",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
            zIndex: 9999,
          }}
        >
          Carregando...
        </div>
      )}
      <Routes />
    </React.Fragment>
  );
}

export default App;
