import axios from 'axios';
import config from '../../config'; // ajuste conforme sua configuração

const SavePermissao = async (formData, csrfToken) => {
  console.log('csrfToken:', csrfToken);


  try {
    const response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
      proc: 'FRONT_Permissao_Inserir',
      requestData: {
        ID_Usuario_Controle: formData.ID_Usuario_Controle,
        ID_Usuario: formData.ID_Usuario,
        ID_Empresa: formData.Empresa,
        ID_Planta: formData.Planta || null,
        ID_Empresa_Parceira: formData.EmpresaParceira,
        ID_Planta_Parceira: formData.PlantaParceira || null,
        ID_Documento: formData.ID_Documento || null,
        ID_Documento_Grupo: formData.ID_Documento_Grupo || null,      
        ID_Intercambio: formData.ID_Intercambio || null,
        Visivel: formData.Visivel ? 'S' : 'N',
        Ativo: formData.Ativo ? 'S' : 'N'
      }
    }, {
      headers: {
        "X-CSRF-Token": csrfToken
      }
    });

    // Log completo da resposta
    if (response.success){
           window.location.href = '/permissao';
    } else {
      console.error('Erro inesperado na resposta da API:', response.data);
      alert('Erro ao salvar permissão1.');
    }
  } catch (error) {
    console.error('Erro ao salvar permissão:', error);
    alert('Erro ao salvar permissão2.');
  }
};

export default SavePermissao;
