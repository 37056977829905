// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-table-container {
  min-height: 400px; /* Ajuste este valor conforme necessário */
  overflow-y: auto; /* Permite a rolagem vertical se necessário */
}

.data-table-container div  {
    
 
}

.data-table-container div div {
     
    
}

.table-row {
  position: relative; /* Define a linha da tabela como ponto de referência para o posicionamento */
}

.dropdown .show {
  position: absolute;
  z-index: 1000; /* Garante que o menu fique acima de outros elementos */
  bottom: 100%; /* Posiciona o menu acima da linha da tabela */
  right: 0; /* Alinha o menu à direita da célula */
}`, "",{"version":3,"sources":["webpack://./src/Pages/Taskman/assets/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAE,0CAA0C;EAC7D,gBAAgB,EAAE,6CAA6C;AACjE;;AAEA;;;AAGA;;AAEA;;;AAGA;;AAEA;EACE,kBAAkB,EAAE,4EAA4E;AAClG;;AAEA;EACE,kBAAkB;EAClB,aAAa,EAAE,uDAAuD;EACtE,YAAY,EAAE,8CAA8C;EAC5D,QAAQ,EAAE,sCAAsC;AAClD","sourcesContent":[".data-table-container {\r\n  min-height: 400px; /* Ajuste este valor conforme necessário */\r\n  overflow-y: auto; /* Permite a rolagem vertical se necessário */\r\n}\r\n\r\n.data-table-container div  {\r\n    \r\n \r\n}\r\n\r\n.data-table-container div div {\r\n     \r\n    \r\n}\r\n\r\n.table-row {\r\n  position: relative; /* Define a linha da tabela como ponto de referência para o posicionamento */\r\n}\r\n\r\n.dropdown .show {\r\n  position: absolute;\r\n  z-index: 1000; /* Garante que o menu fique acima de outros elementos */\r\n  bottom: 100%; /* Posiciona o menu acima da linha da tabela */\r\n  right: 0; /* Alinha o menu à direita da célula */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
