import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { filterSubmenus } from "../../helpers/component_helpers";

const DropdownCustom = props => {
  
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
        direction={props?.direction || 'down'}
      >
        <DropdownToggle
          className="btn dropdown-custom"
          id="dropdown-custom"
          tag="button"
        >
          {props?.icon && (
            <i className={props?.icon} />
          )}
          <span className="d-none d-xl-inline-block">{props?.label}</span>
          <i className={`mdi mdi-chevron-${props?.direction || 'down'} d-none d-xl-inline-block`} />
        </DropdownToggle>
        <DropdownMenu>
          {props.items?.map((item, key) => (
            <Link key={key} to={item?.Codigo_Transacao} className="dropdown-item">              
              { item?.Dropdown === 'S' ?
              <DropdownCustom direction="right" className={item?.Disponivel == 'N' ? 'disabled' : ''} icon={item?.Icone} items={filterSubmenus(props.originalDataMenus, item?.ID_Menu)} label={item?.Descricao || "Dropdown"} /> 
              :                 
                <>
                  {item.Icone && (
                    <i className={item.Icone} />
                  )}
                  {item?.Descricao}
                  {/* {props.t("Profile")}{" "} */}
                </>
              }
              {" "}
            </Link>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default DropdownCustom
