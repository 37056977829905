import React, { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiError, loginSuccess } from "../store/actions";
import BizinBackend from "../helpers/bizin_helper";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => ({
    user: state.login.user,
  }));

  // Estado local para rastrear o status de carregamento
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Função assíncrona para verificar e atualizar o usuário
    const checkUser = async () => {
      if (user && user.access_token) {
        try {
          await BizinBackend.checkAccessToken(user.access_token);
        } catch (error) {
          console.log(error);
          dispatch(apiError('Sessão expirada.'));
          dispatch(loginSuccess(null));
        }
      }
      setIsLoading(false); // Marque como carregado
    };

    // Execute a função de verificação do usuário
    checkUser();
  }, [user, dispatch]);

  // Mostrar carregando enquanto a verificação está em andamento
  if (isLoading) {
    return <div>Carregando...</div>; // Ou qualquer componente de carregamento que você preferir
  }

  // Verifique se o usuário não está autenticado
  if (!user) {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />;
  }

  // Verifique se o usuário precisa alterar a senha
  if (user.Alterar_Senha === 'S') {
    return <Navigate to={{ pathname: "/new-pw", state: { from: props.location } }} />;
  }

  // Renderizar as crianças do componente se todas as verificações forem concluídas
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return <Component {...props} />;
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
