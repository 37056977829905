import React, { useEffect, useState } from "react";
import UsePanel from "./UserPanel";
import OrderStatus from "./OrderStatus";
import Notifications from "./Notifications";
import SocialSource from "./SocialSource";
import OverView from "./OverView";
import RevenueByLocation from "./RevenueByLocation";
import LatestTransation from "./LatestTransation";

import { Row, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { showTopbarInsideAction } from "../../store/actions";
import { TopBarInsideTypes } from "../../constants/layout";
import axios from "axios";
import config from "../../config";
import { useCookies } from "react-cookie";

const Dashboard = () => {
  document.title = "Dashboard";

  const [cookie, setCookie] = useCookies(['_csrf_token'])

  const { menuActivedId } = useSelector(state => ({
    menuActivedId: state.Layout.menuActived.id
  }))

  const dispatch = useDispatch();

  // const menus = [
  //   {
  //     label: "Criar Agendamento",
  //     active: true,
  //     icon: "bx bx-plus-circle",
  //     url: "/cot001",
  //   },
  //   {
  //     label: "Criar Pré Agendamento",
  //     active: true,
  //     icon: "bx bx-plus-circle",
  //     url: "/cot002",
  //   },
  //   {
  //     label: "Consultar Agendamento",
  //     active: true,
  //     icon: "bx bx-search-alt-2",
  //     url: "/dashboard",
  //   },
  //   {
  //     label: "Consultar Agenda Fixa",
  //     active: true,
  //     icon: "bx bx-search-alt-2",
  //     url: "",
  //   },
  //   {
  //     label: "Consulta de Entrega de Agendamento",
  //     active: true,
  //     icon: "bx bx-search-alt-2",
  //     url: "/dashboard",
  //   },
  //   {
  //     label: "Configuração",
  //     active: true,
  //     icon: "bx bxs-cog",
  //     url: "/dashboard",
  //     isDropdown: true,
  //     childrens: [
  //       {
  //         label: "Alterar tema",
  //         url: "/dashboard",
  //       },
  //       {
  //         label: "logout",
  //         url: "/dashboard",
  //       },
  //     ],
  //   },
  // ];

  const getSubmenus = async () => {
    try {
      let response = await axios.post(`${config.URL_SERVER}/execute-procedure`, {
        requestData: {
          1: menuActivedId,
          2: '2415'
        },
        proc: 'FRONT_Framework_Obter_Menu_Transacoes'
      },{
        headers: {
          "X-CSRF-Token": cookie?._csrf_token?.csrfToken
        }
      });

      if(response && response?.success){
        dispatch(
          showTopbarInsideAction({
            isActive: TopBarInsideTypes.isActive.ACTIVE,
            payload: response?.data,
          })
        )
      }else{
        dispatch(
          showTopbarInsideAction({
            isActive: TopBarInsideTypes.isActive.INACTIVE,
            payload: [],
          })
        )
      }
    } catch (error) {
      console.log("Erro to load submenus")
      console.log(error)
    }
  }

  useEffect(() => {
    getSubmenus()
  }, [menuActivedId]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Upzet" breadcrumbItem="Dashboard" />
          {/* User Panel Charts */}
          <UsePanel />

          <Row>
            {/* Overview Chart */}
            <OverView />
            {/* Social Source Chart */}
            <SocialSource />
          </Row>

          <Row>
            {/* Order Stats */}
            <OrderStatus />
            {/* Notifications */}
            <Notifications />
            {/* Revenue by Location Vector Map */}
            <RevenueByLocation />
          </Row>

          {/* Latest Transaction Table */}
          <LatestTransation />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
