
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

//import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

const UserProfile = () => {

  document.title = "Profile | Upzet - React Admin & Dashboard Template";

  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);
  const [avatar, setAvatar] = useState(""); // State for storing avatar data
  const [languageId, setLanguageId] = useState(1); // Estado para armazenar o ID do idioma

  const [fullName, setFullName] = useState(""); // Estado para Nome_Completo
  const [userEmail, setUserEmail] = useState(""); // Estado para Email
  const [userMessage, setUserMessage] = useState(""); // Estado para a Message

 // Acessando os dados do usuário a partir do Redux store
  const { user, error, success } = useSelector((state) => ({
    user: state.login.user, 
    error: state.profile.error,
    success: state.profile.success,
  }));
 
  useEffect(() => {
    if (user) {
      setname(user.Apelido); // Substitua 'Apelido' pelo campo correspondente do seu usuário
      setemail(user.Email); // Substitua 'Email' pelo campo correspondente do seu usuário
      setidx(user.ID_Usuario); // Substitua 'ID_Usuario' pelo campo correspondente do seu usuário

      setFullName(user.Nome_Completo); // Definindo o Nome_Completo
      setUserEmail(user.Email); // Definindo o Email
      setUserMessage(user.message); // Definindo a Message

      setLanguageId(user.ID_Idioma || 1); 
      setAvatar(user.Foto_Usuario || "../../assets/images/users/avatar-1.jpg"); // Set avatar from obj or default
    }

    // if (success) {
    //   setTimeout(() => {
    //     dispatch(resetProfileFlag());
    //   }, 3000);
    // }
  }, [user, success, dispatch]);

  // useEffect(() => {
  //   if (user) {
  //     console.log("entrou:")
  //     if (localStorage.getItem("authUser")) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
      
  //       if (process.env.REACT_APP_DEFAULTAUTH === "bizin") {
  //         console.log('obj' , obj);
  //         setname(obj.Apelido);
  //         setemail(obj.Email);
  //         setidx(obj.ID_Usuario);

  //         setFullName(obj.Nome_Completo); // Definindo o Nome_Completo
  //         setUserEmail(obj.Email); // Definindo o Email
  //         setUserMessage(obj.message); // Definindo a Message

  //         setLanguageId(obj.ID_Idioma || 1); 
  //         setAvatar(obj.Foto_Usuario || "../../assets/images/users/avatar-1.jpg"); // Set avatar from obj or default
  //       } 
  //       // else if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       //   setname(obj.displayName);
  //       //   setemail(obj.email);
  //       //   setidx(obj.uid);
  //       // } else if (
  //       //   process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       //   process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //       // ) {
  //       //   setname(obj.username);
  //       //   setemail(obj.email);
  //       //   setidx(obj.uid);
  //       // }
  //       // setTimeout(() => {
  //       //   dispatch(resetProfileFlag());
  //       // }, 3000);
  //     }
  //   }
  // }, [dispatch, success]);

   // Função para lidar com a mudança no combobox de idiomas
  
  const handleLanguageChange = (e) => {
    setLanguageId(e.target.value);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    // onSubmit: (e) => {
    //   e.preventDefault();
    //   console.log("ON SUBMIT")
    //   console.log(e)
    //   // dispatch(editProfile(values));
    // },
  });

  return (
    <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Upzet" breadcrumbItem="Profile" />

            <Row>
              <Col lg="12">
                {error && error ? (
                  <Alert color="danger">
                    <div>{error}</div>
                  </Alert>
                ) : null}
                {success ? (
                  <Alert color="success">
                    <div>{success}</div>
                  </Alert>
                ) : null}

                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="ms-3">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <h5>{name}</h5>
                          <p className="mb-1">{email}</p>
                          <p className="mb-0">Id no: #{idx}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change User Name</h4>

            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  // onSubmit={(e) => {
                  //   e.preventDefault();
                  //   validation.handleSubmit();
                  //   return false;
                  // }}
                >
                  <div className="form-group">
                    <Label className="form-label">User Name</Label>
                    <Input
                      name="username"
                      className="form-control"
                      placeholder="Enter User Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.username || name || ""}
                      invalid={
                        validation.touched.username &&
                        validation.errors.username
                          ? true
                          : false
                      }
                    />
                    {validation.touched.username &&
                    validation.errors.username ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.username}</div>
                      </FormFeedback>
                    ) : null}
                    <Input name="idx" value={idx} type="hidden" />
                  </div>
                   <div className="form-group">
                      <Label for="languageSelect">Idioma</Label>
                      <Input
                        type="select"
                        name="language"
                        id="languageSelect"
                        value={languageId}
                        onChange={handleLanguageChange}
                      >
                        <option value="1">Brasil</option>
                        <option value="2">USA</option>
                      </Input>
                    </div>
                     <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                       <Label for="languageSelect">Nome Completo</Label>
                        {/* Campo de entrada para Nome_Completo */}
                        <Input
                          type="text"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          placeholder="Nome Completo"
                        />

                         <Label for="languageSelect">Email</Label>
                        <Input
                          type="email"
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                          placeholder="Email"
                        />

                       
                      </div>
                    </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="danger">
                      Update User Name
                    </Button>
                  </div>
                </Form>

              </CardBody>
            </Card>
             {/* Card de Alerta para a Message */}
                  {userMessage && (
                    <Alert color="success">
                      <div>{userMessage}</div>
                    </Alert>
                  )}
          </Container>

        </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
