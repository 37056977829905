import React, { useState } from "react";
import Joyride from "react-joyride";
import { Row, Button, Form, FormGroup, Label, Col, Container, Card, CardBody } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { useSelector, useDispatch } from "react-redux";

import { useCookies } from 'react-cookie';

import { 
  ComboboxEmpresa, 
  ComboboxEmpresaPlanta, 
  ComboboxEmpresaParceira, 
  ComboboxPlantaParceira, 
  InputEmailUsuario, 
  ComboboxDocumento, 
  ComboboxGrupoDocumento, 
  ComboboxIntercambio, 
  SwitchVisivel, 
  SwitchAtivo 
} from '../../components/Common/Fields';

import SavePermissao from "../../components/Common/SavePermissao";

const PermissaoInsert = () => {
  document.title = "Inserir Permissões | Upzet - React Admin & Dashboard Template";

 // Acessando os dados do usuário a partir do Redux store
  const { userControle, error, success } = useSelector((state) => ({
    userControle: state.login.user, 
    error: state.profile.error,
    success: state.profile.success,
  }));


  console.log('user.ID_Usuario',  userControle.ID_Usuario)
 
  const [selectedEmpresa, setSelectedEmpresa] = useState('');
  const [selectedPlanta, setSelectedPlanta] = useState('');
  const [selectedEmpresaParceira, setSelectedEmpresaParceira] = useState('');
  const [selectedPlantaParceira, setSelectedPlantaParceira] = useState('');
  const [selectedDocumento, setSelectedDocumento] = useState('');
  const [selectedGrupoDocumento, setSelectedGrupoDocumento] = useState('');
  const [selectedIntercambio, setSelectedIntercambio] = useState('');

  const [usuario, setUsuario] = useState({ ID_Usuario: '', Nome_Completo: '' });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [visivel, setVisivel] = useState(true);
  const [ativo, setAtivo] = useState(true);

  const [isTourOpen, setIsTourOpen] = useState(false);

  const handleEmailResult = (user) => {
    setUsuario(user);
    setIsEmailValid(true);
    console.log("ID_Usuario recebido:", user.ID_Usuario);
  };

  const handleReset = () => {
    setSelectedEmpresa('');
    setSelectedPlanta('');
    setSelectedEmpresaParceira('');
    setSelectedPlantaParceira('');
    setSelectedDocumento('');
    setSelectedGrupoDocumento('');
    setSelectedIntercambio('');
    setUsuario({ ID_Usuario: '', Nome_Completo: '' });
    setIsEmailValid(false);
  };

  const handleEmpresaChange = (selectedOption) => {
    setSelectedEmpresa(selectedOption?.value || '');
    setSelectedPlanta('');  // Reset planta quando empresa muda
  };

  const handlePlantaChange = (e) => {
    setSelectedPlanta(e.target.value);
  };

  const handleEmpresaParceiraChange = (selectedOption) => {
    setSelectedEmpresaParceira(selectedOption?.value || '');
    setSelectedPlantaParceira('');  // Reset planta parceira quando empresa parceira muda
  };

  const handlePlantaParceiraChange = (e) => {
    setSelectedPlantaParceira(e.target.value);
  };

  const handleGrupoDocumentoChange = (selectedOption) => {
    setSelectedGrupoDocumento(selectedOption ? selectedOption.value : null);
    setSelectedIntercambio('');  // Reset intercambio quando grupo de documentos muda
    setSelectedDocumento('');
  };

  const handleDocumentoChange = (selectedOption) => {    
    setSelectedDocumento(selectedOption ? selectedOption.value : null);
  };

  const handleIntercambioChange = (e) => {
    setSelectedIntercambio(e.target.value);
  };

  // No componente React onde SavePermissao é chamado:
  const [cookies] = useCookies(['_csrf_token']);

const handleSubmit = async (e) => {
  e.preventDefault();

  const dataToSend = {
    ID_Usuario_Controle: userControle.ID_Usuario,
    ID_Usuario: usuario.ID_Usuario,
    Empresa: selectedEmpresa,
      Planta: selectedPlanta ? parseInt(selectedPlanta, 10) : null,
    EmpresaParceira: selectedEmpresaParceira,
     PlantaParceira: selectedPlantaParceira ? parseInt(selectedPlantaParceira, 10) : null, 
    Documento: selectedDocumento,
    GrupoDocumento: selectedGrupoDocumento,
    Intercambio: selectedIntercambio,
    Visivel: visivel,
    Ativo: ativo
  };

  await SavePermissao(dataToSend, cookies._csrf_token.csrfToken);
};

  const tourSteps = [
    {
      target: '.email-usuario',
      content: 'Insira o e-mail do usuário para buscar suas permissões.',
    },
    {
      target: '.empresa-combobox',
      content: 'Selecione a empresa para qual o usuário terá permissões.',
    },
    {
      target: '.planta-combobox',
      content: 'Selecione a planta correspondente.',
    },
    {
      target: '.empresa-parceira-combobox',
      content: 'Selecione uma empresa parceira, se aplicável.',
    },
    {
      target: '.planta-parceira-combobox',
      content: 'Selecione a planta parceira correspondente.',
    },
    {
      target: '.grupo-documento-combobox',
      content: 'Escolha um grupo de documentos.',
    },
    {
      target: '.documento-combobox',
      content: 'Selecione o documento associado ao grupo.',
    },
    {
      target: '.intercambio-combobox',
      content: 'Escolha o intercâmbio relacionado à empresa, empresa parceira e grupo de documentos.',
    },
    {
      target: '.switch-visivel',
      content: 'Determine se a permissão será visível.',
    },
    {
      target: '.switch-ativo',
      content: 'Determine se a permissão estará ativa.',
    },
    {
      target: '.submit-button',
      content: 'Clique para inserir as permissões.',
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Tables" breadcrumbItem="Inserir Permissões" />
          <Button color="info" onClick={() => setIsTourOpen(true)}>Iniciar Tour</Button>
          <Joyride
            steps={tourSteps}
            continuous
            showSkipButton
            showProgress
            run={isTourOpen}
            styles={{
              options: {
                zIndex: 10000,
              },
            }}
            callback={(data) => {
              const { action, type } = data;
              if (type === 'tour:end' || action === 'close') {
                setIsTourOpen(false);
              }
            }}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <div className="email-usuario">
                      <InputEmailUsuario onResult={handleEmailResult} onReset={handleReset} />
                    </div>
                    {isEmailValid && (
                      <>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="Empresa">Empresa</Label>
                              <div className="empresa-combobox">
                                <ComboboxEmpresa ID_Usuario={usuario.ID_Usuario} onChange={handleEmpresaChange} />
                              </div>
                            </FormGroup>

                            {selectedEmpresa && (
                              <FormGroup>
                                <Label for="Planta">Planta</Label>
                                <div className="planta-combobox">
                                  <ComboboxEmpresaPlanta ID_Empresa={selectedEmpresa} onChange={handlePlantaChange} />
                                </div>
                              </FormGroup>
                            )}
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="EmpresaParceira">Empresa Parceira</Label>
                              <div className="empresa-parceira-combobox">
                                <ComboboxEmpresaParceira ID_Usuario={usuario.ID_Usuario} onChange={handleEmpresaParceiraChange} />
                              </div>
                            </FormGroup>
                            {selectedEmpresaParceira && (
                              <FormGroup>
                                <Label for="PlantaParceira">Planta Parceira</Label>
                                <div className="planta-parceira-combobox">
                                  <ComboboxPlantaParceira ID_Empresa_Parceira={selectedEmpresaParceira} onChange={handlePlantaParceiraChange} />
                                </div>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <div className="grupo-documento-combobox">
                              <ComboboxGrupoDocumento onChange={handleGrupoDocumentoChange} />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="documento-combobox">
                              <ComboboxDocumento 
                                ID_Documento_Grupo={selectedGrupoDocumento} 
                                onChange={handleDocumentoChange} 
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            {selectedEmpresa && selectedEmpresaParceira && selectedGrupoDocumento && (
                              <div className="intercambio-combobox">
                                <ComboboxIntercambio
                                  ID_Empresa={selectedEmpresa}
                                  ID_Empresa_Parceira={selectedEmpresaParceira}
                                  ID_Documento_Grupo={selectedGrupoDocumento}
                                  onChange={handleIntercambioChange}
                                />
                              </div>
                            )}
                          </Col>
                        </Row>

                        <hr />

                        <Row>
                          <Col md={6}>
                            <SwitchVisivel className="switch-visivel" isChecked={visivel} onChange={(e) => setVisivel(e.target.checked)} />
                          </Col>
                          <Col md={6}>
                            <SwitchAtivo className="switch-ativo" isChecked={ativo} onChange={(e) => setAtivo(e.target.checked)} />
                          </Col>
                        </Row>

                        <Button color="primary" type="submit" className="submit-button">Inserir</Button>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PermissaoInsert;
